<script lang="ts" setup>
import { computed } from 'vue';
import { EcommerceProductSorting } from '@zyro-inc/site-modules/types';
import { ECOMMERCE_SORTING_VALUES } from '@zyro-inc/site-modules/constants/ecommerce';

interface Props {
	productSorting: EcommerceProductSorting
	sorting: typeof ECOMMERCE_SORTING_VALUES[keyof typeof ECOMMERCE_SORTING_VALUES];
	translations: Record<string, string>;
}

const props = defineProps<Props>();
const emit = defineEmits<{
	'sort-changed': [Event]
}>();
const computedSelectStyles = computed(() => ({
	color: props.productSorting.textColor || '#000',
}));
</script>

<template>
	<div class="product-list-sorting">
		<label
			for="sorting-options"
			:style="computedSelectStyles"
			class="product-list-sorting__label"
		>
			{{ translations.sortBy }}:
		</label>
		<select
			id="sorting-options"
			class="product-list-sorting__select"
			:value="props.sorting"
			:style="computedSelectStyles"
			@change="emit('sort-changed', $event)"
		>
			<option
				v-for="option in productSorting.sortingOptions"
				:key="option.id"
				:value="option.value"
			>
				{{ translations[option.id] }}
			</option>
		</select>
	</div>
</template>

<style lang="scss" scoped>
.product-list-sorting {
	width: 100%;
	text-align: right;
	margin: 0 auto;

	&__label {
		margin-right: 12px;
		font-size: 14px;
	}

	&__select {
		background-color: transparent;
		outline: none;
		border: none;
		font-size: 14px;
		cursor: pointer;
		text-align: right;
		padding-right: 4px;
	}
}
</style>
