<template>
	<div
		class="skeleton-loader"
		:class="{ 'skeleton-loader--full-width': isFullWidth }"
	>
		<div
			v-if="isCategoryListEnabled"
			class="skeleton-loader__categories"
		/>
		<div class="skeleton-loader__wrapper">
			<div
				v-if="isCategoryListEnabled"
				class="skeleton-loader__category"
			/>
			<div class="skeleton-loader__list">
				<div
					v-for="index in columnCount"
					:key="index"
					class="skeleton-loader__item"
				>
					<div
						class="skeleton-loader__image-placeholder"
						:class="`skeleton-loader__image-placeholder--${imageRatio}`"
					/>
					<div class="skeleton-loader__text" />
					<div class="skeleton-loader__text" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		columnCount: {
			type: Number,
			default: 3,
		},
		imageRatio: {
			type: String,
			default: 'original',
		},
		isCategoryListEnabled: {
			type: Boolean,
			default: false,
		},
		isFullWidth: {
			type: Boolean,
			default: false,
		},
	},
});
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";
@import "./shared";

.skeleton-loader {
	max-width: var(--content-width);
	width: 100%;
	display: flex;
	gap: 40px;

	&--full-width {
		max-width: unset;
	}

	&__categories {
		width: 250px;
		height: 200px;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin: 0 auto;
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(v-bind(columnCount), 1fr);
		gap: 24px;
		width: 100%;
	}

	&__category {
		width: 100%;
		height: 60px;
		margin-bottom: 32px;
	}

	&__item {
		display: flex;
		flex-direction: column;
	}

	&__image-placeholder {
		position: relative;
		display: flex;
		width: 100%;
		overflow: hidden;
		height: 0;
		transition: height 0.2s ease-in;

		// aspect ratio: 1;
		// 1 * 100
		padding-bottom: 100%;

		&--portrait {
			// aspect ratio: 4 / 5;
			// 5/4 * 100
			padding-bottom: 125%;

		}

		&--landscape {
			// aspect ratio: 16 / 9
			// 9/16 * 100
			padding-bottom: 56.25%;
		}
	}

	&__image-placeholder,
	&__text,
	&__category,
	&__categories {
		@include skeleton-loader-animation;

		border-radius: 5px;
	}

	&__text {
		width: 100%;
		height: 24px;
		margin-top: 8px;

		&:nth-child(3) {
			width: calc(100% - 120px);
		}
	}
}

@include site-engine-mobile {
	.skeleton-loader {
		flex-direction: column;
		gap: 8px;

		&__categories {
			height: 35px;
			width: 100%;
		}

		&__list {
			grid-template-columns: repeat(2, 1fr);
		}

		&__category {
			display: none;
		}
	}
}

@mixin product-list-mobile($columns) {
	.skeleton-loader {
		flex-direction: column;
		gap: 8px;

		&__categories {
			height: 35px;
			width: 100%;
		}

		&__list {
			grid-template-columns: repeat($columns, 1fr);
		}

		&__category {
			display: none;
		}
	}
}

@include site-engine-mobile {
	@include product-list-mobile(2);
}

@media screen and (max-width: 600px) {
	@include product-list-mobile(1);
}
</style>
