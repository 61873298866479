import { computed } from 'vue';

import {
	COLUMN_COUNT_2,
	productsPerPageByColumnCount,
} from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';

export const useBlockEcommerceProductList = (props) => {
	const blockStyle = computed(() => props.data.settings?.styles);
	const imageHoverEffect = computed(() => props.data.settings?.imageHoverEffect);
	const textColorVars = computed(() => props.data.textColorVars);
	const columnCount = computed(() => props.data.columnCount);
	const productCategoryId = computed(() => props.data.productCategoryId);
	const isButtonEnabled = computed(() => props.data.isButtonEnabled ?? false);
	const buttonDisplay = computed(() => props.data.buttonDisplay);
	const buttonText = computed(() => props.data.buttonText);
	const buttonStyle = computed(() => props.data.buttonStyle);
	const buttonType = computed(() => props.data.buttonType);
	const buttonBorderWidth = computed(() => props.data.buttonBorderWidth);
	const ribbonStyle = computed(() => props.data.ribbonStyle);
	const imageRatio = computed(() => props.data.imageRatio);
	const backgroundColor = computed(() => props.data.background.color);
	const isCategoryListEnabled = computed(() => props.data.isCategoryListEnabled);
	const productIds = computed(() => props.data.productIds);
	const isFullWidth = computed(() => props.data.isFullWidth);
	const isTotalProductCountShown = computed(() => props.data.isTotalProductCountShown);
	const columnGap = computed(() => props.data.columnGap);
	const isListCentered = computed(() => props.data.isListCentered);
	const isButtonFullWidth = computed(() => props.data.isButtonFullWidth);

	const productSorting = computed(() => ({
		...props.data.productSorting,
		sortingOptions: props.data.productSorting?.sortingOptions?.filter((option) => option.isEnabled),
	}));
	const productsPerPage = computed(
		() => props.data.productsPerPage || productsPerPageByColumnCount[columnCount.value || COLUMN_COUNT_2],
	);

	return {
		textColorVars,
		blockStyle,
		imageHoverEffect,
		columnCount,
		productsPerPage,
		productCategoryId,
		isButtonEnabled,
		buttonDisplay,
		buttonText,
		buttonStyle,
		buttonType,
		buttonBorderWidth,
		ribbonStyle,
		imageRatio,
		backgroundColor,
		isCategoryListEnabled,
		productSorting,
		isFullWidth,
		isTotalProductCountShown,
		productIds,
		columnGap,
		isListCentered,
		isButtonFullWidth,
	};
};
